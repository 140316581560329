<template>
  <df-delete-modal
    persistent
    :text="$t('SowingsList.delete_sowing_modal.text')"
    :title="$t('SowingsList.delete_sowing_modal.title')"
    :width="594"
    @action-click="deleteSowing"
    @close="closeModal"
  >
    <template #content>
      <sowing-infos-card :sowing="sowing" />
    </template>
  </df-delete-modal>
</template>

<script>
import DfDeleteModal from '@/lib/Modal/DfDeleteModal.vue'
import SowingInfosCard from '@/modules/sowingsList/components/SowingInfosCard.vue'

export default {
  name: 'SowingsListDeleteModal',

  components: {
    DfDeleteModal,
    SowingInfosCard,
  },

  props: {
    sowing: {
      required: true,
      type: Object,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    deleteSowing() {
      this.$emit('action')
    },
  },
}
</script>
