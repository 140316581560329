<template>
  <v-expansion-panel class="field-sowings-list">
    <v-expansion-panel-header class="d-flex list__header">
      <div class="df-flex-sm align-center header__title">
        <h1>
          {{ fieldSowing.field_name }}
        </h1>
        <span>
          {{
            sowingQuantities +
            ' ' +
            $t('SowingsList.field_sowings_list.sowings')
          }}
        </span>
      </div>
      <df-button depressed variant="text-secondary" @click="redirectToField">
        <p>{{ $t('SowingsList.field_sowings_list.see_field') }}</p>
      </df-button>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="list-content">
      <without-item-card
        v-if="showEmptyStateFilter"
        name="filter"
        text="SowingsList.field_sowings_list.filter_not_found_text"
        title="SowingsList.field_sowings_list.filter_not_found_title"
      />
      <without-item-card
        v-else-if="showEmptyStateExecuteSowing"
        name="execute-sowing"
        text="SowingsList.field_sowings_list.sowing_not_found_text"
        title="SowingsList.field_sowings_list.sowing_not_found_title"
      />
      <div v-else>
        <sowings-list-item
          v-for="sowing in fieldSowing.sowings"
          :key="sowing.id"
          :sowing="sowing"
        />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import SowingsListItem from '@/modules/sowingsList/components/list/SowingsListItem.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'FieldSowingsList',

  components: {
    DfButton,
    SowingsListItem,
    WithoutItemCard,
  },

  props: {
    currentFarmId: {
      required: true,
      type: Number,
    },
    fieldSowing: {
      required: true,
      type: Object,
    },
    periodFilter: {
      required: true,
      type: Object,
    },
  },

  provide() {
    return {
      fieldObj: {
        name: this.fieldSowing.field_name,
        value: String(this.fieldSowing.field_id),
      },
    }
  },

  computed: {
    showEmptyStateExecuteSowing() {
      return (
        !this.fieldSowing.sowings.length &&
        Number(this.periodFilter.value) >= new Date().getFullYear()
      )
    },
    showEmptyStateFilter() {
      return (
        !this.fieldSowing.sowings.length &&
        Number(this.periodFilter.value) < new Date().getFullYear()
      )
    },
    sowingQuantities() {
      return this.fieldSowing.sowings.length
    },
  },

  methods: {
    redirectToField() {
      logEvent(events.sowingsListModule.SowingsList.see_field)
      this.$router.push({
        path: '/plot',
        query: {
          plot_id: this.fieldSowing.field_id,
          fazenda_id: this.currentFarmId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.field-sowings-list {
  border-radius: 8px;
  border: 1px solid #e6e9e6;

  .v-expansion-panel {
    border-radius: 0px 0px 8px 8px;
  }
  .list__header {
    padding: 16px 24px;

    @include d(m) {
      padding: 16px;
    }
    .header__title {
      flex-grow: 1;

      h1 {
        font-family: 'Rubik';
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #1a2b46;
      }
      span {
        font-family: 'Rubik';
        color: #5b6459;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .v-btn {
      margin: auto 8px auto 0px;
      flex-grow: 0;
    }
    p {
      color: #39af49;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .list-content {
    border-top: 1px solid #e6e9e6;
  }
}
.without-item-card {
  border: none;
}
::v-deep .v-expansion-panel-header--active {
  min-height: 60px;
}
::v-deep .v-expansion-panel-header__icon {
  margin: 0px;

  & .v-icon {
    color: #5b6459 !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
