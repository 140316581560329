<template>
  <df-modal
    persistent
    :action-name="$t('SowingsList.harvest_modal.register')"
    :loading="loading"
    :title="$t('SowingsList.harvest_modal.title')"
    @action-click="registerHarvest"
    @close="closeHarvestModal"
  >
    <template #card-content>
      <v-form ref="harvestForm" class="df-flex-xl df-flex-col harvest-modal">
        <div class="df-flex-l">
          <div class="df-col-6 df-col-12-m">
            <df-input
              v-model="harvestQuantity"
              :label="$t('SowingsList.harvest_modal.bags_per_area')"
              :placeholder="
                $t('SowingsList.harvest_modal.insert_harvested_quantity')
              "
              :rules="[required]"
            />
          </div>
          <div class="df-col-6 df-col-12-m">
            <df-date-picker
              v-model="harvestDate"
              :label="$t('SowingsList.harvest_modal.harvest_date')"
              :placeholder="$t('SowingsList.harvest_modal.insert_date')"
              :rules="[required]"
            />
          </div>
        </div>
        <df-radio-button
          v-model="desiccation"
          :label="$t('SowingsList.harvest_modal.did_desiccation')"
          :options="desiccationOptions"
        />
        <v-divider />
        <div class="df-flex-none flex-grow-0">
          <df-checkbox size="large" @change="handleCheckboxChange">
            <span>{{ $t('SowingsList.harvest_modal.lost_harvest') }}</span>
          </df-checkbox>
        </div>
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import DfRadioButton from '@/lib/components/Button/DfRadioButton.vue'
import sowingsListService from '@/modules/sowingsList/services/sowingsListService.js'
import { required } from '@/utils/formRules'
import { logEvent, events } from '@/services/analytics'
import '@/lib/components/Checkbox/DfCheckbox.js'

export default {
  name: 'HarvestModal',

  components: {
    DfDatePicker,
    DfInput,
    DfModal,
    DfRadioButton,
  },

  props: {
    sowingId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      desiccation: '',
      harvestDate: '',
      harvestQuantity: null,
      loading: false,
      lostHarvest: false,
      required,
    }
  },

  inject: {
    requestFieldSowingsList: {
      from: 'requestFieldSowingsList',
    },
  },

  computed: {
    desiccationOptions() {
      return [
        {
          label: this.$t('SowingsList.harvest_modal.no'),
          value: 'no',
        },
        {
          label: this.$t('SowingsList.harvest_modal.yes'),
          value: 'yes',
        },
      ]
    },
  },

  methods: {
    closeHarvestModal() {
      this.$emit('close')
    },
    handleCheckboxChange(event) {
      this.lostHarvest = event.detail
    },
    async registerHarvest() {
      if (!this.$refs.harvestForm.validate()) return
      logEvent(events.sowingsListModule.SowingsListfinish_register_crop)
      this.loading = true
      const params = {
        desiccation: this.desiccation,
        harvest_date: this.harvestDate,
        lost_harvest: this.lostHarvest,
        quantity: this.harvestQuantity,
        sowing_id: this.sowingId,
      }
      try {
        await sowingsListService.registerHarvest(params)
        this.requestFieldSowingsList()
        this.closeHarvestModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scope>
.harvest-modal {
  span {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
