<template>
  <div>
    <df-page-header :title="$t('SowingsList.header.title')">
      <template #actions>
        <df-button
          data-id="home-execute-sowing"
          :disabled="disabledActions"
          @click="openExecuteSowingModal"
        >
          {{ $t('SowingsList.header.action') }}
        </df-button>
      </template>
    </df-page-header>
    <execute-sowing-modal
      v-if="isExecuteSowingModalOpened"
      @close="closeExecuteSowingModal"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import ExecuteSowingModal from '@/modules/sowingsList/components/modal/ExecuteSowingModal.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SowingsListHeader',

  components: {
    DfButton,
    DfPageHeader,
    ExecuteSowingModal,
  },

  props: {
    disabledActions: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isExecuteSowingModalOpened: false,
    }
  },

  methods: {
    closeExecuteSowingModal() {
      this.isExecuteSowingModalOpened = false
    },
    openExecuteSowingModal() {
      logEvent(events.sowingsListModule.SowingsList.add_sowing_in_season)
      this.isExecuteSowingModalOpened = true
    },
  },
}
</script>
