<template>
  <div class="sowings-list-item">
    <div class="df-flex-l df-flex-col-t df-flex-col-m item__header">
      <div class="df-flex-xl df-flex-col-m item__title">
        <div class="df-flex-sm flex-grow-0 cursor" @click="redirectToSowing">
          <font-awesome-icon icon="seedling" />
          <h2>
            {{ $t(`SowingsList.variety_list_item.${sowing.crop_message_key}`) }}
            | {{ sowing.variety_name }}
          </h2>
        </div>
        <sowing-item-status-chip :status="sowing.status" />
      </div>
      <div class="df-flex-l align-center flex-grow-0 item__infos">
        <div v-if="sowing.sown_at" class="info__block">
          <h3>{{ $t('SowingsList.variety_list_item.sown_at') }}</h3>
          <p>{{ sowing.sown_at.formatDate() }}</p>
        </div>
        <div v-if="sowing.harvested_at" class="info__block">
          <h3>{{ $t('SowingsList.variety_list_item.harvested_at') }}</h3>
          <p>{{ sowing.harvested_at.formatDate() }}</p>
        </div>
        <div v-if="shouldShowToSowDate" class="info__block">
          <h3>{{ $t('SowingsList.variety_list_item.to_sow_date') }}</h3>
          <p>{{ sowing.to_sow_date.formatDate() }}</p>
        </div>
        <div v-if="shouldShowEstimatedHarvestDate" class="info__block">
          <h3>
            {{ $t('SowingsList.variety_list_item.estimated_harvest_date') }}
          </h3>
          <p>{{ sowing.estimated_harvest_date.formatDate() }}</p>
        </div>
        <div v-if="shouldShowGrowthStage" class="info__block">
          <h3>{{ $t('SowingsList.variety_list_item.growth_stage') }}</h3>
          <p>{{ sowing.growth_stage }}</p>
        </div>
        <div v-if="sowing.productivity" class="info__block">
          <div class="df-flex-xm align-center productivity__title">
            <font-awesome-icon icon="chart-line" />
            <h3>{{ $t('SowingsList.variety_list_item.productivity') }}</h3>
          </div>
          <p class="info__productivity">
            {{ sowing.productivity }}
            {{ $unitMeasures['performance'][$currentLanguage()] }}
          </p>
        </div>
      </div>
      <df-button
        v-if="shouldShowHarvestButton"
        class="items__harvest-button"
        variant="text-secondary"
        @click="openHarvestModal"
      >
        {{ $t('SowingsList.variety_list_item.register_harvest') }}
      </df-button>
      <df-dropdown-list
        class="item__actions"
        icon="ellipsis-v"
        :items="sowingActions"
      />
    </div>
    <harvest-modal
      v-if="isHarvestModalOpened"
      :sowing-id="sowing.id"
      @close="closeHarvestModal"
    />
    <execute-sowing-modal
      v-if="isExecuteSowingModalOpened"
      editing
      :sowing-id="sowing.id"
      @close="closeExecuteSowingModal"
    />
    <df-copy-modal
      v-if="isCopyModalOpened"
      :description="
        $t('SowingsPlanning.copy_sowing_modal.select_field_description')
      "
      :loading="loadingCopyModal"
      :title="$t('SowingsPlanning.copy_sowing_modal.copy_sowing')"
      @action-click="copySowing"
      @close="closeCopyModal"
    >
      <template #card>
        <sowing-infos-card :sowing="sowing" />
      </template>
    </df-copy-modal>
    <sowings-list-delete-modal
      v-if="isDeleteSowingModalOpened"
      :sowing="sowing"
      @action="deleteSowing"
      @close="closeDeleteModal"
    />
  </div>
</template>

<script>
const DfCopyModal = () => import('@/lib/Modal/DfCopyModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
import ExecuteSowingModal from '@/modules/sowingsList/components/modal/ExecuteSowingModal.vue'
import HarvestModal from '@/modules/sowingsList/components/modal/HarvestModal.vue'
import SowingsListDeleteModal from '@/modules/sowingsList/components/modal/SowingsListDeleteModal.vue'
import sowingsListService from '@/modules/sowingsList/services/sowingsListService.js'
import SowingInfosCard from '@/modules/sowingsList/components/SowingInfosCard.vue'
import SowingItemStatusChip from '@/modules/sowingsList/components/list/SowingItemStatusChip.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SowingsListItem',

  components: {
    DfCopyModal,
    DfButton,
    DfDropdownList,
    ExecuteSowingModal,
    HarvestModal,
    SowingsListDeleteModal,
    SowingInfosCard,
    SowingItemStatusChip,
  },

  props: {
    sowing: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isCopyModalOpened: false,
      isDeleteSowingModalOpened: false,
      isExecuteSowingModalOpened: false,
      isHarvestModalOpened: false,
      loadingCopyModal: false,
    }
  },

  inject: ['requestFieldSowingsList'],

  computed: {
    shouldShowEstimatedHarvestDate() {
      return !this.sowing.harvested_at && this.sowing.estimated_harvest_date
    },
    shouldShowGrowthStage() {
      const growthStageStatus = ['ready_to_harvest', 'ongoing']
      return (
        growthStageStatus.includes(this.sowing.status) &&
        this.sowing.growth_stage
      )
    },
    shouldShowHarvestButton() {
      return this.sowing.status === 'ready_to_harvest'
    },
    shouldShowToSowDate() {
      return !this.sowing.sown_at && this.sowing.to_sow_date
    },
    sowingActions() {
      return [
        {
          name: this.$t('SowingsList.variety_list_item.sowing_actions.edit'),
          icon: 'edit',
          action: () => {
            logEvent(events.sowingsListModule.SowingsList.edit_sowing)
            this.isExecuteSowingModalOpened = true
          },
        },
        {
          name: this.$t(
            'SowingsList.variety_list_item.sowing_actions.see_sowing'
          ),
          icon: 'seedling',
          action: () => {
            logEvent(events.sowingsListModule.SowingsList.see_sowing)
            this.$router.push({
              path: '/sowing',
              query: {
                sowing_id: this.sowing.id,
              },
            })
          },
        },
        {
          name: this.$t('SowingsList.variety_list_item.sowing_actions.copy_to'),
          icon: 'copy',
          action: () => {
            logEvent(events.sowingsListModule.SowingsList.copy)
            this.isCopyModalOpened = true
          },
        },
        {
          name: this.$t('SowingsList.variety_list_item.sowing_actions.delete'),
          color: '#F23528',
          icon: 'trash',
          action: () => {
            logEvent(events.sowingsListModule.SowingsList.delete_sowing)
            this.isDeleteSowingModalOpened = true
          },
        },
      ]
    },
  },

  methods: {
    closeCopyModal() {
      this.isCopyModalOpened = false
    },
    closeDeleteModal() {
      this.isDeleteSowingModalOpened = false
    },
    closeExecuteSowingModal() {
      this.isExecuteSowingModalOpened = false
    },
    closeHarvestModal() {
      this.isHarvestModalOpened = false
    },
    async copySowing(selectedFieldIds) {
      if (!selectedFieldIds.length) {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t('SowingsPlanning.exceptions.no_field_selected')
        )
        return
      }
      const params = {
        field_ids: selectedFieldIds,
        sowing_id: this.sowing.id,
      }
      this.loadingCopyModal = true
      try {
        await sowingsListService.copyExecutedSowing(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.copy_sowing_modal.successful_request_title')
        )
        logEvent(events.sowingsPlanningModule.SowingsPlanning.finish_copy)
        this.requestFieldSowingsList()
        this.closeCopyModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingCopyModal = false
      }
    },
    async deleteSowing() {
      try {
        const params = {
          sowing_id: this.sowing.id,
        }
        await sowingsListService.deleteSowing(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsList.delete_sowing_modal.sowing_deleted')
        )
        this.requestFieldSowingsList()
        this.closeDeleteModal()
      } catch (error) {
        console.error(error)
      }
    },
    openHarvestModal() {
      logEvent(events.sowingsListModule.SowingsList.register_crop)
      this.isHarvestModalOpened = true
    },
    redirectToSowing() {
      logEvent(events.sowingsListModule.SowingsList.see_sowing)
      this.$router.push({
        path: '/sowing',
        query: {
          sowing_id: this.sowing.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sowings-list-item {
  padding: 16px 24px;

  @include d(t) {
    padding: 16px;
  }
  @include d(m) {
    padding: 16px;
  }
  &:not(:first-child) {
    border-top: 1px solid #e6e9e6;
  }

  .item__header {
    position: relative;

    .item__title {
      justify-content: start;
      align-items: center;

      @include d(t) {
        height: 40px;
      }
      @include d(m) {
        align-items: start;
        gap: 8px;
      }
      .cursor {
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }
      h2 {
        overflow: hidden;
        color: #1a2b46;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .item__infos {
      padding-right: 24px;

      @include d(t) {
        padding-right: 0px;
      }
      @include d(m) {
        padding-right: 0px;
        gap: 8px;
      }

      .info__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;

        @include d(t) {
          flex: 1 0 0;
        }
        @include d(m) {
          flex: 1 0 0;
        }
        h3 {
          text-align: center;
          font-weight: 500;
          line-height: 16px;

          @include d(m) {
            text-align: start;
            font-size: 12px;
          }
        }
        p {
          font-weight: 400;
          line-height: 18px;

          @include d(m) {
            font-size: 12px;
          }
        }
        .productivity__title {
          flex-wrap: nowrap;
        }
        .info__productivity {
          color: #39af49;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .items__harvest-button {
      border: 1px solid #e6e9e6;

      @include d(t) {
        position: absolute;
        top: 0px;
        right: 52px;
      }
    }
    .item__actions {
      @include d(t) {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      @include d(m) {
        position: absolute;
        top: -5px;
        right: -9px;
      }
    }
  }
}
::v-deep .v-expansion-panel-header__icon .v-icon {
  color: #5b6459 !important;
}
::v-deep .v-btn__content svg {
  color: #5b6459;
}
::v-deep .v-expansion-panel-header__icon {
  @include d(m) {
    position: absolute;
  }
}
.v-btn::before {
  background-color: transparent !important;
}
.v-btn:hover {
  background-color: #d8f2dc !important;
  box-shadow: none !important;
}
</style>
