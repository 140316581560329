<template>
  <div class="df-flex-l df-flex-col sowing-infos-card">
    <div class="df-flex-m justify-space-between align-center">
      <div class="df-flex-sm align-center card-content__title">
        <font-awesome-icon icon="seedling" />
        <h2>{{ $t(sowing.crop_message_key) }} | {{ sowing.variety_name }}</h2>
      </div>
      <sowing-item-status-chip :status="sowing.status" />
    </div>
    <div class="df-flex-m card-content__text">
      <p>
        {{ $t('SowingsList.sowing_infos_card.sowing_date') }}:
        <span>
          {{ (sowing.sown_at || sowing.to_sow_date).formatDate() }}
        </span>
        <span class="text__divider"></span>
      </p>
      <p>
        {{ $t('SowingsList.sowing_infos_card.harvest_date') }}:
        <span>
          {{
            (sowing.harvested_at || sowing.estimated_harvest_date).formatDate()
          }}
          <span v-if="sowing.productivity" class="text__divider"></span>
        </span>
      </p>
      <p v-if="sowing.productivity">
        {{ $t('SowingsList.sowing_infos_card.productivity') }}:
        <span class="text__productivity">
          {{ sowing.productivity }}
          {{ $unitMeasures['performance'][$currentLanguage()] }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import SowingItemStatusChip from '@/modules/sowingsList/components/list/SowingItemStatusChip.vue'

export default {
  name: 'SowingInfosCard',

  components: {
    SowingItemStatusChip,
  },

  props: {
    sowing: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-infos-card {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e6e9e6;
  background: #f8f9f8;

  .card-content__title {
    flex-wrap: nowrap;

    svg {
      height: 14px;
      width: 14px;
      color: #1a2b46;
    }
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .card-content__text {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;

    p {
      font-weight: 500;
      line-height: 16px;

      .text__divider::before {
        content: '';
        display: inline-block;
        vertical-align: top;
        width: 2px;
        height: 16px;
        background-color: #e6e9e6;
        margin-left: 8px;
      }
    }
    span {
      font-weight: 400;
      line-height: 18px;
    }
    .text__productivity {
      color: #39af49;
    }
  }
}
</style>
