class DfCheckbox extends HTMLElement {
  static get observedAttributes() {
    return ['disabled', 'size']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'disabled') {
      this._disabled = newValue !== null
      const checkboxWrapper = this.shadowRoot.querySelector('.df-checkbox')
      const checkbox = this.shadowRoot.querySelector('#checkbox')

      if (this._disabled) {
        checkboxWrapper.classList.add('disabled')
        checkbox.classList.add('disabled')
        checkbox.disabled = true
      }

      this.updateSlotTextColor()
    }

    if (name === 'size') {
      const checkboxSizeObj = {
        small: '12px',
        medium: '16px',
        large: '20px',
      }
      const iconSizeObj = {
        small: '8px',
        medium: '12px',
        large: '16px',
      }

      const checkbox = this.shadowRoot.querySelector('#checkbox')
      checkbox.style.setProperty('--checkbox-height', checkboxSizeObj[newValue])
      checkbox.style.setProperty('--checkbox-width', checkboxSizeObj[newValue])
      checkbox.style.setProperty('--checkbox-font-size', iconSizeObj[newValue])
    }
  }

  connectedCallback() {
    if (this._listenersAdded) return
    this._listenersAdded = true

    if (!this._disabled) {
      const checkbox = this.shadowRoot.querySelector('#checkbox')
      const container = this.shadowRoot.querySelector('.df-checkbox')

      container.addEventListener('click', (event) => {
        if (event.target !== checkbox) {
          checkbox.checked = !checkbox.checked
          this.changeSelection()
        }
      })

      checkbox.addEventListener('change', () => {
        this.changeSelection()
      })
    }
  }

  changeSelection() {
    const checkbox = this.shadowRoot.querySelector('#checkbox')
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: checkbox.checked,
        bubbles: true,
        composed: true,
      })
    )
  }

  updateSlotTextColor() {
    const slot = this.shadowRoot.querySelector('slot')
    if (this._disabled) {
      const assignedNodes = slot.assignedNodes()
      assignedNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          this.applyTextColor(node)
        }
      })
    }
  }

  applyTextColor(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const textElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span']
      if (textElements.includes(node.nodeName.toLowerCase())) {
        node.style.color = '#AAB2A9'
      }

      node.childNodes.forEach((childNode) => {
        this.applyTextColor(childNode)
      })
    }
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._listenersAdded = false
    this._disabled = false

    this.shadowRoot.innerHTML = `
      <style scoped>
        .df-checkbox {
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          cursor: pointer;
        }
        .checkbox {
          max-height: var(--checkbox-height, 16px);
          max-width: var(--checkbox-width, 16px);
          min-height: var(--checkbox-height, 16px);
          min-width: var(--checkbox-width, 16px);
          border-radius: 4px;
          border: 1px solid #AAB2A9;
          margin: 0px;
          appearance: none;
          -webkit-appearance: none;
          display: inline-block;
          position: relative;
        }
        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          background-color: #F8F9F8;
        }
        input[type="checkbox"]:not(.disabled):hover {
          background-color: #D8F2DC;
          border-color: #39AF49;
          cursor: pointer;
        }
        .df-checkbox:not(.disabled):hover .checkbox {
          background-color: #D8F2DC;
          border-color: #39AF49;
        }
        input[type="checkbox"]:checked {
          background-color: #39AF49;
          border-color: #39AF49;
        }
        .df-checkbox:not(.disabled):hover input[type="checkbox"]:checked {
          background-color: #39AF49;
          border-color: #39AF49;
        }
        .checkbox:checked::after {
          content: '✔';
          color: #FFF;
          font-size: var(--checkbox-font-size, 12px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .disabled {
          cursor: auto;
        }
      </style>
      <div class="df-checkbox">
        <input id="checkbox" class="checkbox" type="checkbox">
        <slot></slot>
      </div>
    `
  }
}

customElements.define('df-checkbox', DfCheckbox)
